import React from 'react'
import { Link } from 'gatsby'
import tw, { css, styled } from 'twin.macro'

import Layout from '../components/layout'
import Meta from '../components/meta'
import { graphql } from 'gatsby'
import filterLocale from '../components/utils/filter-locale'
import Tabs from '../components/product-comparison/tabs'

const ComparisonSection = styled.section`
	${tw`w-11/12 m-auto pt-16`}
	@media(max-width: 767px) {
		${tw`overflow-x-scroll`}
	}
`
const ComparisonContainer = styled.div`
	@media (max-width: 767px) {
		min-width: 550px;
	}
`
const PageTitle = tw.h1`font-display font-bold text-4xl mb-12`

const ComparisonTable = ({ data: { comparisontable, productImage } }) => {
	const locale = 'en-NZ'

	const TabsArray = [
		{
			tabName: 'Residential Pool Cleaners',
			brand: 'Dolphin',
			tag: 'residential',
			slug: 'residential',
			active: false,
		},
		{
			tabName: 'Commercial Pool Cleaners',
			brand: 'Dolphin',
			tag: 'commercial',
			slug: 'commercial',
			active: false,
		},
		{
			tabName: 'Mineral Swim',
			brand: 'Mineral Swim',
			slug: 'mineral-swim',
			active: false,
		},
		{
			tabName: 'Ozone Swim',
			brand: 'Ozone Swim',
			slug: 'ozone-swim',
			active: false,
		},
	]

	const [{ node }] = filterLocale(comparisontable.edges, locale)
	const filterAllByLocale = (items, locale) => {
		return items.filter((item, i) => {
			return item.node.node_locale === locale
		})
	}
	const imageNode = filterAllByLocale(productImage.edges, locale)
	const { seoTitle, seoKeywords, seoDescription } = node
	return (
		<Layout>
			<Meta
				title={seoTitle}
				keywords={seoKeywords}
				description={seoDescription}
				lang={locale}
			/>
			<ComparisonSection>
				<ComparisonContainer>
					<PageTitle>{seoTitle}</PageTitle>
					<p tw="text-lg sm:text-2xl mb-4 sm:mb-6">
						Not sure which product is right for you? Compare up to 3 products.
						Still not sure?{' '}
						<Link
							tw="underline hover:no-underline"
							to="/en-NZ/support/contact/?subject=which+product+is+right+for+me#contact-form"
						>
							Contact Us
						</Link>
						.
					</p>
					<Tabs
						title={seoTitle}
						tabsArray={TabsArray}
						imageNode={imageNode}
						locale={locale}
					/>
				</ComparisonContainer>
			</ComparisonSection>
		</Layout>
	)
}

export const comparisonTableQuery = graphql`
	query comparisonTableQueryNz {
		comparisontable: allContentfulPage(
			filter: { contentful_id: { eq: "3UbSR2Shf6Q4REP3kx5w2g" } }
		) {
			edges {
				node {
					node_locale
					seoTitle
					seoKeywords
					seoDescription
				}
			}
		}
		productImage: allContentfulProduct {
			edges {
				node {
					node_locale
					series
					seoTitle
					slug
					model
					images {
						fluid(maxWidth: 600, maxHeight: 400, quality: 60) {
							src
							srcWebp
						}
						description
					}
				}
			}
		}
	}
`

export default ComparisonTable
